import React, { useEffect, useState } from 'react';
import { Link } from 'gatsby';
import { Container, Typography } from '@material-ui/core';
import Brand from 'images/brand.svg';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import { Consumer } from 'store/createContext';

function Header() {
  const [pos, setPos] = useState('top');
  useEffect(() => {
    document.addEventListener('scroll', e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled > 10) {
        setPos('moved');
      } else {
        setPos('top');
      }
    });
  }, []);

  return (
    <Box bgcolor="primary.main" className={`menu-header ${pos}`}>
      <Container maxWidth="xl">
        <Box
          className="menu-header-inner"
          component={Grid}
          container
          display="flex"
          alignItems="center"
          py={10}
        >
          <Box
            component={Grid}
            item
            xs
            display="flex"
            justifyContent="flex-start"
          >
            <Typography
              component="a"
              variant="subtitle2"
              color="textSecondary"
              className="menu-btn"
              href="https://bowquarter-secure.dwellant.com/"
              target="_blank"
              rel="noreferrer"
            >
              LOGIN
            </Typography>
          </Box>
          <Box component={Grid} item xs display="flex" justifyContent="center">
            <Link to="/">
              <Brand className="brand" />
            </Link>
          </Box>
          <Box
            component={Grid}
            item
            xs
            display="flex"
            justifyContent="flex-end"
          >
            <Consumer>
              {({ toggleMenu }) => (
                <Typography
                  component="button"
                  variant="subtitle2"
                  color="textSecondary"
                  className="menu-btn"
                  onClick={toggleMenu}
                >
                  MENU
                </Typography>
              )}
            </Consumer>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}

export default Header;
