import { createGlobalStyle } from 'styled-components';

export default createGlobalStyle`
    h1, h2, h3, h4, h5, h6 {
        font-size: inherit;
        font-weight: inherit;
    }
    h3 {
        font-size: 3rem;
    }
    a {
        text-decoration: none;
    }
    strong, b {
        font-family: Gotham-Bold, Arial, sans-serif;
    }
    .menuOpen {
        overflow: hidden;
    }
    .spaced-grid-wrapper {
        height: auto;
        overflow: hidden;
    }
    img {
        width: 100%;
    }
    .text-color-heading {
        color: #A8744B;
    }
    .text-uppercase {
        text-transform: uppercase;
    }
    .text-bold {
        font-weight: 600;
    }
    .standard-button {
        padding: 0;
        background: transparent;
        outline: 0;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        border-bottom: 2px solid #A8744B;
        width: auto;
        display: inline-block;
        transition: all 0.3s ease-in-out;
    }
    .standard-button a span {
        transition: color 0.3s ease-in-out;
    }
    .standard-button:hover {
        border-bottom: 2px solid #BB8F4C;
    }
    .standard-button:hover a span {
        color: #BB8F4C !important;
    }
    .standard-button.body-text-link-wrapper {
        margin-top: 20px;
        margin-bottom: 20px;
    }
    .postcode-button {
        padding: 0;
        background: transparent;
        outline: 0;
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
        border-bottom: 0;
        margin: 0;
        cursor: pointer;
        font-size: 12px;
        color: #C7C7C7;
    }
    .bg-blue {
        background-color: #0F161F;
    }
    .footer-wrapper {
        position: relative;
        z-index: 200;
        background: #fff;
        min-height: 100vh;
    }
    .large-link {
        display: flex;
        align-items: center;
    }
    .text-secondary {
        color: #A8744B;
    }
    .link-xl {
        font-family: Gotham-Medium, Arial, sans-serif;
        font-size: 2.625rem;
        @media only screen and (min-width: 600px) {
            font-size: 5rem;
        }
        @media only screen and (min-width: 800px) {
            font-size: 5rem;
        }
    }
    .link-xl.link-xl-icon {
        margin-left: 80px;
        @media only screen and (min-width: 600px) {
            margin-left: 100px;
        }
        @media only screen and (min-width: 800px) {
            margin-left: 120px;
        }
    }
    .footer-menu li {
        list-style: none;
    }
    .divder {
        background: #333333;
        width: 1px;
        margin-top: 4px;
        margin-bottom: 4px;
    }
    .menu-btn {
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        cursor: pointer;
        outline: 0;
        transition: color 0.3s ease-in-out;
    }
    .menu-btn:hover {
        color: #BB8F4C;
    }
    .menu-item {
        transition: color 0.3s ease-in-out;
    }
    .menu-item:hover {
        color: #BB8F4C;
    }
    .h-100 {
        height: 100%;
    }
    .margin-bottom-none {
        margin-bottom: 0;
    }
    .MuiIconButton-edgeEnd {
        color: #A8744B;
        padding: 12px 20px;
    }
    .dot.hidden {
        visibility: hidden;
    }
    .list-box {
        border: 2px solid #A8744B;
        width: 100%;
    }
    .list-box.faded {
        border 2px solid rgba(168,116,75,0.20);
    }
    .circle {
        width: 15px;
        height: 15px;
        border-radius: 50%;
    }
    .circle.purple {
        background-color: #544A89;
    }
    .circle.green {
        background-color: #69894A;
    }
    .circle.blue {
        background-color: #4A8489;
    }
    .circle.brownred {
        background-color: #894A4A;
    }
    .circle.lgreen {
        background-color: #4A896E;
    }
    .circle.pink {
        background-color: #AF4C97;
    }
    .circle.yellow {
        background-color: #C97C2B;
    }
    .circle.lred {
        background-color: #DE4D4D;
    }
    .btn-plain-text {
        cursor: pointer;
        background: transparent;
        border: 0;
        padding: 0;
        margin: 0;
        outline: 0;
        line-height: 1;
    }
    .MuiPaper-root.MuiAccordion-root.Mui-expanded {
        margin-top: 0 !important;
    }
    .text-center {
        text-align: center;
    }
    .blur-up {
		-webkit-filter: blur(5px);
		filter: blur(5px);
		transition: filter 400ms, -webkit-filter 400ms;
	}

	.blur-up.lazyloaded {
		-webkit-filter: blur(0);
		filter: blur(0);
	}
    .pl-5 {
        padding-left: 120px;
    }
    .body-text-link {
        display: flex;
        align-items: center;
    }
    .body-text-link span {
        margin-bottom: 0;
    }
    .text-link {
        color: #A8744B;
        transition: color 0.3s ease-in-out;
    }
    .text-link span,
    .text-link svg {
        transition: color 0.3s ease-in-out;
    }
    .text-link:hover {
        color: #BB8F4C;
    }
    .text-link:hover span,
    .text-link:hover svg {
        color: #BB8F4C;
    }
    .brand {
        @media only screen and (max-width: 600px) {
            width: 100px;
        }
    }
    .estate-map {
        height: auto;
    }
    .spacer-wrapper {
        position: relative;
        z-index: 1;
    }
    .parallax-wrapper {
        z-index: 0;
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
    }

    .parallax-item {
        position: relative;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: 100vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
    }

    .project-200vh {
        position: relative;
        top: -100vh;
        bottom: 0px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        width: 100%;
        height: 200vh;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -webkit-flex-direction: column;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-pack: start;
        -webkit-justify-content: flex-start;
        -ms-flex-pack: start;
        justify-content: flex-start;
        -webkit-box-align: stretch;
        -webkit-align-items: stretch;
        -ms-flex-align: stretch;
        align-items: stretch;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .project-image-mask {
        position: -webkit-sticky;
        position: sticky;
        top: 0px;
        bottom: 0px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        overflow: hidden;
        width: 100%;
        height: 100vh;
        -webkit-box-pack: center;
        -webkit-justify-content: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-flex: 0;
        -webkit-flex: 0 0 auto;
        -ms-flex: 0 0 auto;
        flex: 0 0 auto;
    }

    .project-image {
        position: relative;
        width: 100%;
        height: 100%;
        -o-object-fit: cover;
        object-fit: cover;
        will-change: transform;
        transform: translate3d(0px, 0px, 0px) scale3d(1.01264, 1.01264, 1) rotateX(
    0deg
    ) rotateY(
    0deg
    ) rotateZ(
    0deg
    ) skew(
    0deg
    , 
    0deg
    );
        transform-style: preserve-3d;
    }

    .project-info {
        position: absolute;
        left: 0%;
        top: auto;
        right: 0%;
        bottom: 0%;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        height: auto;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
        -webkit-flex-direction: row;
        -ms-flex-direction: row;
        flex-direction: row;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        color: #fff;
    }

    .parallax-item-0 {
        z-index: 6;
    }
    .parallax-item-1 {
        z-index: 5;
    }
    .parallax-item-2 {
        z-index: 4;
    }
    .parallax-item-3 {
        z-index: 3;
    }
    .parallax-item-4 {
        z-index: 2;
    }
    .parallax-item-5 {
        z-index: 1;
    }
    .bg-white {
        background-color: #fff;
    }
    .project-image-overlay {
        position: absolute;
        left: 0%;
        top: 0%;
        right: 0%;
        bottom: 0%;
        background-color: #000;
        opacity: 0;
        will-change: opacity;
    }
    .menu-header {
        position: sticky;
        top: 0;
        z-index: 300 !important;
    }
    .brand {
        transition: all 0.3s ease-in-out;
    }
    .menu-header.moved .menu-header-inner .brand {
        transform: scale(0.5);
    }
    .menu-header .menu-header-inner {
        transition: all 0.3s ease-in-out;
    }
    .menu-header.moved .menu-header-inner {
        padding-top: 0px !important;
        padding-bottom: 0px !important;
    }
    .window-scrolled .main-nav-overlay-wrapper .brand {
        transform: scale(0.5);
    }
    .window-scrolled .main-nav-overlay-inner {
        padding-top: 0px !important;
    }
`;
