const linkResolver = doc => {
  // Pretty URLs for known types
  if (doc.type === 'homepage') return '/';
  // Fallback for other types, in case new custom types get created
  if (doc.uid) {
    return `/${doc.uid}`;
  }
  return '/';
};

module.exports = linkResolver;
