import React, { useState, useEffect } from 'react';
import { StaticQuery, graphql } from 'gatsby';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from 'gatsby-link';
import linkResolver from 'helpers/linkResolver';
import CallMadeIcon from '@material-ui/icons/CallMade';
import Typography from '@material-ui/core/Typography';

const MenuQuery = graphql`
  query {
    prismicGlobal {
      data {
        footer_menu_item {
          link_title
          link {
            url
            uid
            type
            id
          }
        }
      }
    }
  }
`;

function Footer() {
  const [date, setDate] = useState();
  const getYear = () => setDate(new Date().getFullYear());

  useEffect(() => {
    getYear();
  }, []);
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Box pt={20} className="footer-wrapper">
          <Container maxWidth="xl">
            <Box width="100%">
              <Box
                component={Link}
                to="/the-location"
                display="flex"
                alignItems="center"
                className="text-link"
              >
                <span className="text-secondary link-xl">LOCATION</span>
                <CallMadeIcon className="text-secondary link-xl link-xl-icon" />
              </Box>
            </Box>
            <Box component={Grid} container pt={40} pb={20}>
              <Grid item xs={12} md={8} />
              <Grid item xs={12} md={4}>
                <Typography
                  variant="subtitle1"
                  color="textSecondary"
                  className="text-uppercase"
                >
                  Estate team
                </Typography>
                <Typography variant="body2" className="margin-bottom-none">
                  <a
                    href="mailto:concierge@thebowquarter.co.uk"
                    target="_blank"
                    rel="noreferrer"
                    style={{ color: 'inherit' }}
                  >
                    concierge@thebowquarter.co.uk
                  </a>
                </Typography>
                <Typography variant="body2" className="margin-bottom-none">
                  020 8983 0052
                </Typography>
                <Typography variant="body2" className="margin-bottom-none">
                  07594 087788
                </Typography>
                <Box component="div" pt={3}>
                  <Typography
                    variant="subtitle1"
                    color="textSecondary"
                    className="text-uppercase"
                  >
                    Managing agent
                  </Typography>
                  <Typography variant="body2" className="margin-bottom-none">
                    <a
                      href="www.rendallandrittner.co.uk"
                      target="_blank"
                      rel="noreferrer"
                      style={{ color: 'inherit' }}
                    >
                      www.rendallandrittner.co.uk
                    </a>
                  </Typography>
                </Box>
              </Grid>
            </Box>
            <Box component={Grid} container pb={10}>
              <Grid item xs={12} md={8}>
                <Typography variant="body2">
                  © {date} The Bow Quarter.{' '}
                  <a
                    href="https://www.dewsign.co.uk/"
                    target="_blank"
                    rel="noreferrer"
                    className="text-link"
                  >
                    Website by Dewsign
                  </a>
                </Typography>
              </Grid>
              <Grid item xs={12} md={4}>
                <Box
                  className="footer-menu"
                  component="ul"
                  display="flex"
                  flexDirection="row"
                  p={0}
                  m={0}
                >
                  {data.prismicGlobal.data.footer_menu_item.map(
                    (item, key, arr) => {
                      return (
                        <React.Fragment key={key}>
                          <li>
                            <Typography
                              variant="body2"
                              component={Link}
                              to={linkResolver(item.link)}
                              className="text-link"
                            >
                              {item.link_title}
                            </Typography>
                          </li>
                          {arr.length - 1 !== key && (
                            <Box component="li" className="divder" mx={2} />
                          )}
                        </React.Fragment>
                      );
                    }
                  )}
                </Box>
              </Grid>
            </Box>
          </Container>
        </Box>
      )}
    />
  );
}

export default Footer;
