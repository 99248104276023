import React from 'react';
import styled from 'styled-components';
import { StaticQuery, graphql } from 'gatsby';
import { Consumer } from 'store/createContext';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Brand from 'images/brand.svg';
import Link from 'gatsby-link';
import Container from '@material-ui/core/Container';
import linkResolver from 'helpers/linkResolver';

const Wrapper = styled.div`
  background-color: rgb(15, 22, 31);
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 500;
`;

const MenuQuery = graphql`
  query {
    prismicGlobal {
      data {
        menu_item {
          link {
            uid
            type
            id
            url
          }
          link_title {
            html
            raw
            text
          }
        }
      }
    }
  }
`;

function MainNavigation() {
  return (
    <StaticQuery
      query={`${MenuQuery}`}
      render={data => (
        <Wrapper className="main-nav-overlay-wrapper">
          <Container maxWidth="xl" className="h-100">
            <Box height="100%" display="flex" flexDirection="column">
              <Box
                component={Grid}
                container
                display="flex"
                alignItems="center"
                pt={10}
                mb="auto"
                className="main-nav-overlay-inner"
              >
                <Box
                  component={Grid}
                  item
                  xs
                  display="flex"
                  justifyContent="flex-start"
                >
                  <Typography
                    component="a"
                    variant="subtitle2"
                    color="textSecondary"
                    className="menu-btn"
                    href="https://bowquarter-secure.dwellant.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    LOGIN
                  </Typography>
                </Box>
                <Box
                  component={Grid}
                  item
                  xs
                  display="flex"
                  justifyContent="center"
                >
                  <Link to="/">
                    <Brand className="brand" />
                  </Link>
                </Box>
                <Box
                  component={Grid}
                  item
                  xs
                  display="flex"
                  justifyContent="flex-end"
                >
                  <Consumer>
                    {({ toggleMenu }) => (
                      <Typography
                        component="button"
                        variant="subtitle2"
                        color="textSecondary"
                        className="menu-btn"
                        onClick={toggleMenu}
                      >
                        CLOSE
                      </Typography>
                    )}
                  </Consumer>
                </Box>
              </Box>
              <Consumer>
                {({ toggleMenu }) => (
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                    mb="auto"
                  >
                    {data.prismicGlobal.data.menu_item.map((item, key) => {
                      return (
                        <Box key={key} mb={4}>
                          <Typography
                            component={Link}
                            to={linkResolver(item.link)}
                            onClick={toggleMenu}
                            className="text-uppercase menu-item"
                            color="textSecondary"
                            variant="caption"
                          >
                            {item.link_title.text}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                )}
              </Consumer>
            </Box>
          </Container>
        </Wrapper>
      )}
    />
  );
}

export default MainNavigation;
