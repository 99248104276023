import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { StaticQuery, graphql } from 'gatsby';
import Helmet from 'react-helmet';
import Head from 'components/head';
import Header from 'components/header';
import Footer from 'components/footer';
import GlobalStyle from 'global.css.js';
import { Consumer } from 'store/createContext';
import MainNavigation from 'components/header/MainNavigation';

function Layout(props) {
  const { data, children } = props;
  const [pos, setPos] = useState('top');
  useEffect(() => {
    document.addEventListener('scroll', e => {
      let scrolled = document.scrollingElement.scrollTop;
      if (scrolled > 10) {
        setPos('window-scrolled');
      } else {
        setPos('window-top');
      }
    });
  }, []);
  return (
    <Consumer>
      {({ menuOpen }) => (
        <React.Fragment>
          <Helmet>
            <body
              className={`${menuOpen ? 'menuOpen' : 'menuClosed'} ${pos}`}
            />
          </Helmet>
          <GlobalStyle />
          <Head />
          <React.Fragment>
            {menuOpen && <MainNavigation menu={menuOpen} />}
          </React.Fragment>
          <Header title={data.site.siteMetadata.siteTitle} />
          {children}
          <Footer />
        </React.Fragment>
      )}
    </Consumer>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  data: PropTypes.object.isRequired,
};

const LayoutWithQuery = props => (
  <StaticQuery
    query={graphql`
      query LayoutQuery {
        site {
          siteMetadata {
            siteTitle
          }
        }
      }
    `}
    render={data => <Layout data={data} {...props} />}
  />
);

LayoutWithQuery.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LayoutWithQuery;
